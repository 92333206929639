import React, { Component, useState, useEffect } from 'react'
import {
    BrowserRouter as Router, Switch, Route, Redirect, useLocation, useSearchParams
} from 'react-router-dom'
import { registrationConfirmation, resendRegistrationToken } from '../util/APIUtils';
import '../App.css';
import { Typography } from 'antd';

const ConfirmEmail = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState(
        {
            token: null,
            isConfirm: false,
            isresendConfirm: false,
            message: ''
        }
    )

    const confirmToken = (token) => {
        registrationConfirmation(token)
            .then(response => {
                setState({
                    ...state,
                    message: response.data,
                    isConfirm: true
                });
            }).catch(error => {
                setState({
                    ...state,
                    message: error.data,
                    // token:error.token,
                    isConfirm: false
                });
                resendRegistrationToken(token)
                    .then(response => {
                        setState({
                            ...state,
                            message: response.data,
                            isresendConfirm: true
                        });
                    }).catch(error => {
                        setState({
                            ...state,
                            message: error.data,
                            // token:error.token,
                            isresendConfirm: false
                        });
                    });
            });
    }


    useEffect(() => {
        const token = searchParams.get('token');
        // const token1 = searchParams.get('token');
        setState({
            token: token
        });
        // console.log("Get data1==>", searchParams.get('token'));
        if (token) {
        confirmToken(token);
        }
    }, []);


    return (
        <div>
            {state.isConfirm ? (
                <Typography.Title level={3} className="App-body"> Учетная запись подтверждена. Можете <a href="/login">войти</a> в Личный кабинет.</Typography.Title>
            ) : (
                <Typography.Title level={3} className="App-body">Извините. Подтверждение регистрации просрочено. Вам направлено новое письмо с подтверждением.</Typography.Title>
            )}
        </div>
    )

}
export default ConfirmEmail;