import React, { Component } from 'react';
import { login } from '../../util/APIUtils';
import './Login.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN, REFRESH_TOKEN, EXPIRATION, DEVICE_ID } from '../../constants';
import { Input, Button, notification, Form, Space, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
const FormItem = Form.Item;



const Login = (props) => {
    const {
        onLogin,
        ...restProps
    } = props;

    const handleSubmit = (values) => {
        const loginRequest = Object.assign({}, values);
        localStorage.setItem(DEVICE_ID, uuidv4());
        const DeviceInfo = {
            deviceId: localStorage.getItem(DEVICE_ID),
            deviceType: "DEVICE_TYPE_ANDROID",
            notificationToken: "N1"
        }
        loginRequest.deviceInfo = DeviceInfo;
        login(loginRequest)
            .then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(REFRESH_TOKEN, response.refreshToken);
                localStorage.setItem(EXPIRATION, Date.now() + response.expiryDuration - 10000);

                onLogin();

            }).catch(error => {
                if (!error.success) {
                    notification.error({
                        message: 'Портал ЖКХ',
                        description: error.data || 'Извините, в настоящий момент сервис не доступен. Попробуйте позднее'
                    });
                } else {
                    notification.error({
                        message: 'Портал ЖКХ',
                        description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз.'
                    });
                }
            });
    }


    return (
        <div className="login-container">
            <Form onFinish={handleSubmit} className="login-form">
                <Typography className="page-title">Вход в личный кабинет</Typography>
                <FormItem name="email" rules={[{ required: true, message: "Пожалуйста, введите Ваш логин!" }]}>
                    <Input className="email"
                        prefix={<UserOutlined />}
                        size="large"
                        name="usernameOrEmail"
                        placeholder="Логин" />
                </FormItem>
                <FormItem name="password" rules={[{ required: true, message: "Пожалуйста, введите Ваш пароль!" }]}>
                    <Input.Password
                        prefix={<LockOutlined />}
                        size="large"
                        name="password"
                        type="password"
                        placeholder="Пароль"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" size="large" className="login-form-button button">Войти</Button>
                    <Space>
                        <Link to="/signup">Зарегистрироваться!</Link>  <Link to="/forgotpassword">Забыли пароль?</Link>
                    </Space>
                </FormItem>

            </Form>
        </div>
    );

}


export default Login;