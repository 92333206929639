import React from 'react';
import 'antd/dist/reset.css';
import { Spin} from 'antd';
import { UserOutlined,LockOutlined, Loading3QuartersOutlined   } from '@ant-design/icons';

export default function LoadingIndicator(props) {
    const antIcon = <Loading3QuartersOutlined  style={{ fontSize: 30 }} spin />;
    return (
        <Spin indicator={antIcon} style = {{display: 'block', textAlign: 'center', margin: 120}}/>
    );
}