import React, { useState, Component } from 'react'
import { API_MERCHANT } from './../constants/index'
import './Welcome.css'
import LoadingIndicator from './LoadingIndicator';
import NotFound from './NotFound';
import EventBus from "./EventBus";
import ServerError from './ServerError';
import CheckAuthentication from './CheckAuthentication';
import { Typography } from 'antd';
const Welcome = (props) => {
  const [user, setUser] = useState();
  const {
      isAuthenticated,
      currentUser,
      ...restProps
  } = props;

  return (
    <div>
      <Typography.Title level={2}> Добро пожаловать в Личный кабинет</Typography.Title>
      <Typography.Title level={2}>Здесь Вы можете: </Typography.Title>
      <table align="center">
        <tbody>
          <tr><td align="left"><Typography.Title level={3}>1. Скачать платежный документ</Typography.Title></td></tr>
          <tr><td align="left"><Typography.Title level={3}>2. Оплатить жилищные услуги, коммунальные услуги, капитальный ремонт с помощью банковских карт</Typography.Title></td></tr>
          <tr><td align="left"><Typography.Title level={3}>3. Передать показания приборов учета</Typography.Title></td></tr>
          <tr><td align="left"><Typography.Title level={3}>4. Подключить регулярную отправку платежных документов на Вашу электронную почту</Typography.Title></td></tr>
        </tbody>
      </table>

    </div>
  )

}
export default Welcome;