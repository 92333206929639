import { API_BASE_URL, ACCESS_TOKEN, EXPIRATION } from '../constants';
import EventBus from "../common/EventBus";
import download from 'downloadjs';
import { notification } from 'antd';
import { API_MERCHANT } from './../constants/index'



const request = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
 //   if (localStorage.getItem(EXPIRATION)&&
 //   localStorage.getItem(EXPIRATION)<Date.now()){
 //       console.log('Token expired. Refresh need');
 //   }


    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    // console.log('HTTP request:  ', options.url);
    const response = await fetch(options.url, options);
    const json = await response.json();
    // console.log('HTTP status: ', response.status);
    // console.log('HTTP OK: ', response.ok);
    if (!response.ok) {
        if (response.status === 406) {
            EventBus.dispatch("handleLogout");
        }
        return Promise.reject(json);
    }
    EventBus.dispatch("handleRefresh");
    return json;
};

const logoutrequest = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
 //   if (localStorage.getItem(EXPIRATION)&&
 //   localStorage.getItem(EXPIRATION)<Date.now()){
 //       console.log('Token expired. Refresh need');
 //   }


    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    // console.log('HTTP request:  ', options.url);
    const response = await fetch(options.url, options);
    const json = await response.json();
    // console.log('HTTP status: ', response.status);
    // console.log('HTTP OK: ', response.ok);
    if (!response.ok) {
        if (response.status === 406) {
            EventBus.dispatch("handleLogout");
        }
        return Promise.reject(json);
    }
    return json;
};
const nonauthrequest = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    // console.log('HTTP request: ', options.url);
    const response = await fetch(options.url, options);
    const json = await response.json();
    // console.log('HTTP status: ', response.status);
    // console.log('HTTP OK: ', response.ok);
    if (!response.ok) {
        return Promise.reject(json);
    }
    return json;
};
const requestmerchant = (options) => {
    var http = new XMLHttpRequest();
    var url = 'get_data.php';
    var params = 'orem=ipsum&name=binny';
    http.open('POST', url, true);
    
    //Send the proper header information along with the request
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    
    http.onreadystatechange = function() {//Call a function when the state changes.
        if(http.readyState === 4 && http.status === 200) {
            alert(http.responseText);
        }
    }
    http.send(params)
};

let fnGetFileNameFromContentDispostionHeader = function (header) {
    let contentDispostion = header.split(';');
    const fileNameToken = `filename*=UTF-8''`;
    let fileName = 'downloaded.pdf';
    for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
            fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
            break;
        }
    }

    return fileName;
};

const requestfile1 = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
 //   if (localStorage.getItem(EXPIRATION)&&
 //   localStorage.getItem(EXPIRATION)<Date.now()){
 //       console.log('Token expired. Refresh need');
 //   }


    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    // console.log('HTTP request:  ', options.url);
    try {
        const res = await fetch(options.url, options);
        const resObj = ({
            filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition')),
            filetype: res.headers.get('Content-Type'),
            blob: await res.blob()
        });
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        // const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
        // const newBlob = new Blob([resObj.blob], { type: [resObj.filetype] });
        download(resObj.blob, resObj.filename, resObj.filetype);
    } catch (error) {
        if (error.status === 404) {
            // this.setState({
            //     notFound: true,
            //     isLoading: false
            // });
            EventBus.dispatch("handleLogout");
        } else if (error.status === 406) {
            // this.setState({
            //     serverError: true,
            //     isLoading: false
            // }); 
            EventBus.dispatch("handleLogout");
        } else {
            // this.setState({
            //     serverError: true,
            //     isLoading: false
            // }); 
            notification.error({
                message: 'Портал ЖКХ',
                description: error.message,
            });
        }
    }
};
export function login(loginRequest) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}
export function logout(logoutRequest) {
    return logoutrequest({
        url: API_BASE_URL + "/user/logout",
        method: 'POST',
        body: JSON.stringify(logoutRequest)
    });
}
export function signup(signupRequest) {
    // console.log('signupRequest ', signupRequest);
    return nonauthrequest({
        url: API_BASE_URL + "/auth/register",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}
export function resetpasswordreq(resetRequest) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/password/reset",
        method: 'POST',
        body: JSON.stringify(resetRequest)
    });
}
export function resetpasswordlink(signupRequest) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/password/resetlink",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}
export function checkUsernameAvailability(username) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/checkUsernameInUse?username=" + username,
        method: 'GET'
    });
}

export function checkEmailAvailability(email) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/checkEmailInUse?email=" + email,
        method: 'GET'
    });
}
export function registrationConfirmation(token){
    return nonauthrequest({
        url: API_BASE_URL + "/auth/registrationConfirmation?token=" + token,
        method: 'GET'
    });
}
export function checkTokenAvailability(token) {
    // console.log('validateTokenAvailability2',token);
    return nonauthrequest({
        url: API_BASE_URL + "/auth/checkTokenInUse?token=" + token,
        method: 'GET'
    });
}


export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me1",
        method: 'GET'
    });
}

export function getUserProfile(username) {
    return request({
        url: API_BASE_URL + "/user/profile/" + username,
        method: 'GET'
    });
}
export function getFlat() {
    return request({
        url: API_BASE_URL + "/user/flats",
        method: 'GET'
    });
}
export function getCounter(flat) {
    return request({
        url: API_BASE_URL + "/user/counter?flat="+flat,
        method: 'GET'
    });
}


export function setCountVal(Request) {
    return request({
        url: API_BASE_URL + "/user/countervalue",
        method: 'POST',
        body: JSON.stringify(Request)
    });
}
export function refresh(Request) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/refresh",
        method: 'POST',
        body: JSON.stringify(Request)
    });
}
export function resend(token) {
    return nonauthrequest({
        url: API_BASE_URL + "/auth/resendRegistrationToken" + token,
        method: 'GET'
    });
}
export function profileset(Request) {
    return request({
        url: API_BASE_URL + "/user/profileset",
        method: 'POST',
        body: JSON.stringify(Request)
    });
}
export function addtoken(token) {
    return request({
        url: API_BASE_URL + "/user/addtoken?token=" + token,
        method: 'GET',
    });
}
    export function resendRegistrationToken(token) {
        // console.log('validateTokenAvailability2',token);
    return nonauthrequest({
        url: API_BASE_URL + "/auth/resendRegistrationToken?token=" + token,
        method: 'GET'
    });  
}
export function getAmount(Request) {
    return request({
        url: API_BASE_URL + "/user/amount",
        method: 'POST',
        body: JSON.stringify(Request)
    });
    
} 
export function getReport(Request) {
    return requestfile1({
        url: API_BASE_URL + "/user/report",
        method: 'POST',
        body: JSON.stringify(Request)
    });
    
}
export function getMailReport(Request) {
    return request({
        url: API_BASE_URL + "/user/mailreport",
        method: 'POST',
        body: JSON.stringify(Request)
    });
    
}
        
export function makePayment1(Request) { 
var form = document.createElement('form');
form.style.visibility = 'hidden'; // no user interaction is necessary
form.method = 'POST'; // forms by default use GET query strings
form.action = API_MERCHANT + "/pay";
for (var key in Request) {
  var input = document.createElement('input');
  input.name = key;
  input.value = Request[key];
  form.appendChild(input); // add key/value pair to form
}
document.body.appendChild(form); // forms cannot be submitted outside of body
form.submit(); 
}


export function makePayment2(details,merchant,sign) { 
const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');
console.log('FormBody: ' ,formBody);
// const formBody='amount=4052.38&description=TESTTERMINAL_GM_01&merchant_order_id=5083';
const sign1 = merchant + ' ' + sign;
console.log('X-Authorization: ' ,sign1);
fetch(API_MERCHANT + "/checkout/pay", {
  method: 'POST',
  cache: 'no-cache',
//   mode: 'no-cors',
  headers: {
    'X-Authorization' :  sign1,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: formBody
}).then(response=>response.text().then(data=>{
    if(!response.ok) { return Promise.reject(data); }
    const parser = new DOMParser();
    const xmlDOM = parser.parseFromString(data,"text/xml");
    const value = xmlDOM.getElementsByTagName("redirect")[0].childNodes[0].nodeValue;
    console.log(value);
    window.location.replace(value)
}))
.catch(err=>{console.log("Utils: ",err);
    return Promise.reject(err)})

    }
export function getnumorder() {
    return request({
        url: API_BASE_URL + "/user/seqorder",
        method: 'GET',
    });
}
export function makeOrder(Request) {
    return request({
        url: API_BASE_URL + "/user/order",
        method: 'POST',
        body: JSON.stringify(Request)
    });
    
}
export function makePayment3(Request) {
    return request2({
        url: API_BASE_URL + "/user/payorder",
        method: 'POST',
        body: JSON.stringify(Request)
    });
    
}
const request2 = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
 //   if (localStorage.getItem(EXPIRATION)&&
 //   localStorage.getItem(EXPIRATION)<Date.now()){
 //       console.log('Token expired. Refresh need');
 //   }


    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    // console.log('HTTP request:  ', options.url);
    const response = await fetch(options.url, options)
    .then(response=>response.text().then(data=>{
        if(!response.ok) { return Promise.reject(data); }
        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(data,"text/xml");
        const value = xmlDOM.getElementsByTagName("redirect")[0].childNodes[0].nodeValue;
        console.log(value);
        window.location.replace(value)
    }))
    .catch(err=>{console.log("Utils1: ",err);
        return Promise.reject(err)})
    
};