import React, { Component, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getUserProfile, profileset } from '../../util/APIUtils';
import { formatDate } from '../../util/Helpers';
import LoadingIndicator from '../../common/LoadingIndicator';
import './Profile.css';
import NotFound from '../../common/NotFound';
import CheckAuthentication from '../../common/CheckAuthentication';
import ServerError from '../../common/ServerError';
import { Form, Input, Button, Checkbox, notification, Typography } from 'antd';
const Profile = (props) => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [serverError, setServerError] = useState(false);
    // const { username } = useParams();
    const {
        isAuthenticated,
        currentUser,
        ...restProps
    } = props;
    const loadUserProfile = (username) => {
        if (username !== null) {
            setIsLoading(true);
            getUserProfile(username)
                .then(response => {
                    setUser(response);
                    setIsLoading(false);
                }).catch(error => {
                    if (error.status === 404) {
                        setIsLoading(false);
                        setNotFound(true);
                    } else {
                        setIsLoading(false);
                        setServerError(true);
                    }
                });
        }
    }
    const onFinish = (values) => {
        const request = {
            send: values.remember
        }
        profileset(request)
            .then(response => {
                setUser(response);
                setIsLoading(false);
                notification.success({
                    message: 'Портал ЖКХ',
                    description: "Профиль сохранен",
                });
            }).catch(error => {
                if (error.status === 404) {
                    setIsLoading(false);
                    setNotFound(true);
                } else {
                    setIsLoading(false);
                    setServerError(true);
                }
            });
    }
    const onFinishFailed = () => {
        setIsLoading(false);
    }

    useEffect(() => {
        loadUserProfile(currentUser.username);
    }, [currentUser.username]);


    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (notFound) {
        return <NotFound />;
    }

    if (serverError) {
        return <ServerError />;
    }
    if (!isAuthenticated) {
        return <CheckAuthentication {...props} />
    }

    return (
        <div className="profile">
            {
                user ? (
                    <div className="user-profile">
                        <div className="user-details">
                            <Typography.Title level={2}>Профиль</Typography.Title>
                            <Form
                                name="basic"
                                className='user-form'
                                labelCol={{
                                    span: 0,
                                }}
                                wrapperCol={{
                                    span: 32,
                                }}
                                initialValues={{
                                    remember: user.send
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >


                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 0,
                                        span: 32,
                                    }}
                                >
                                    <Checkbox>Рассылка платежных документов на электронную почту</Checkbox>
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 0,
                                        span: 32,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        Сохранить
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div className="user-summary">
                                <Typography>{user.name}</Typography>
                                <Typography>Пользователь: {user.username}</Typography>
                                <Typography>Зарегистрирован {formatDate(user.joinedAt)}</Typography>
                            </div>
                        </div>

                    </div>
                ) : null
            }
        </div>
    );

}

export default Profile;