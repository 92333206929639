import React, { useState,useEffect } from 'react';
import './App.css';
import Login from './user/login/Login1';
import ConfirmEmail from './confirmation/ConfirmEmail1';
import ConfirmPayment from './confirmation/ConfirmPayment';
import Resetpassword from './confirmation/resetpassword';
import LoginHome from './user/counters/LoginHome1';
import Signup from './user/signup/Signup1';
import Profile from './user/profile/Profile1';
import Amount from './user/amount/Amount1';
import AppHeader from './common/AppHeader1';
import AppFooter from './common/AppFooter';
import EventBus from './common/EventBus';
import NotFound from './common/NotFound';
import Welcome from './common/Welcome';
import Rules from './common/Rules';
import EmailSend from './common/EmailSend'
import { ACCESS_TOKEN, EXPIRATION, REFRESH_TOKEN, DEVICE_ID } from './constants';
import { getCurrentUser, refresh, setCountVal, logout } from './util/APIUtils';
import { Route,useNavigate,Routes} from 'react-router-dom';
import { notification } from 'antd';
import Tokenexpired from './errorpages/tokenexpired';
import Forgotpassword from './user/forgotpassword/forgotpassword1';
import Addtoken from './user/addtoken/Addtoken1';
import Warning from './common/Warning';


const App = () => {
  let navigate = useNavigate();
  const [state, setState] = useState(
    {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false
    });
    const loadCurrentUser = () => {
      setState({...state,
        isLoading: true
      });
      getCurrentUser()
        .then(response => {
          setState({
            ...state,
            currentUser: response,
            isAuthenticated: true,
            isLoading: false
          });
        }).catch(error => {
          setState({
            ...state,
            isLoading: false
          });
        });
    }
    const handleUnaccesible = (redirectTo = "/login",) => {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(EXPIRATION);
      setState({
        ...state,
        currentUser: null,
        isAuthenticated: false
      });
  
      navigate(redirectTo);
  
    }

  
  useEffect(() => {

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });

    loadCurrentUser();
    EventBus.on("handleLogout", () => {
      handleUnaccesible();
    });
    EventBus.on("handleRefresh", () => {
      handleRefresh();

    });
    EventBus.on("handleWritevalue", (e) => {
      handleWritevalue(e);
    });

    return () => {

      EventBus.remove("handleLogout");
      EventBus.remove("handleRefresh");
      EventBus.remove("handleWritevalue");
    }
  }, []);

  const handleLogout = (redirectTo = "/login", notificationType = "success", description = "Вы  вышли из личного кабинета.") => {
    
    
    const DeviceInfo = {
      deviceId: localStorage.getItem(DEVICE_ID),
      deviceType: "DEVICE_TYPE_ANDROID",
      notificationToken: "N1"
  }
    const logoutrequest= {
      deviceInfo : DeviceInfo
    }
    logout(logoutrequest);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRATION);
    setState({
      ...state,
      currentUser: null,
      isAuthenticated: false
    });
    notification[notificationType]({
      message: 'Портал ЖКХ',
      description: description,
    });
    navigate(redirectTo);
  }


  const handleLogin = (redirectTo = "/welcome") => {
    setState({
      ...state,
      isAuthenticated: true
    });
    loadCurrentUser();
    navigate(redirectTo);
  }

  const handleMenu = (e) => {
    if (e.key === 'logout') {
      handleLogout();
    } else {
      navigate(e.key);
    }
  }

  const handleRefresh = () => {
    const refreshrequest = {
      refreshToken: localStorage.getItem(REFRESH_TOKEN)
    }
    refresh(refreshrequest)
      .then(response => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        localStorage.setItem(REFRESH_TOKEN, response.refreshToken);
        localStorage.setItem(EXPIRATION, Date.now() + response.expiryDuration - 10000);
      })
      .catch(error => {
        console.log('Response: ', error.data);
        if (!error.success) {
          notification.error({
            message: 'Портал ЖКХ',
            description: error.data
          });
        } else {
          notification.error({
            message: 'Портал ЖКХ',
            description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
          });
        }
      });
  }
  const handleWritevalue = (request) => {
    // console.log('APP HTTP request:  ', request);
    setCountVal(request)
      .then(response => {

        notification.success({
          message: 'Портал ЖКХ',
          description: "Ваши показания успешно зарегистрированы в системе."
        });
      }).catch(error => {
        if (error.status === 404) {
          notification.error({
            message: 'Портал ЖКХ',
            description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
          });
        } else if (error.status === 406) {
          EventBus.dispatch("handleLogout");
        } else {
          notification.error({
            message: 'Портал ЖКХ',
            description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
          });
        }
      });
  }
  return (
    <div className="app-container">
      <AppHeader isAuthenticated={state.isAuthenticated}
        currentUser={state.currentUser}
        onLogout={handleLogout}
        onClick={handleMenu} />
      <div className="container">
      <Routes>
          <Route path="/"               element={state.isAuthenticated ? (<Welcome    isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin} />)} />
          <Route path="/counters"       element={state.isAuthenticated ? (<LoginHome  isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin} />)} />
          <Route path="/amount"         element={state.isAuthenticated ? (<Amount     isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin} />)} />
          <Route path="/login"          element={state.isAuthenticated ? (<Welcome    isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin}/>)}/>
          <Route path="/signup"         element={state.isAuthenticated ? (<Welcome    isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Signup />)}/>
          <Route path="/users"          element={state.isAuthenticated ? (<Profile    isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin} />)} />
          <Route path="/addtoken"       element={state.isAuthenticated ? (<Addtoken   isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin} />)} />
          <Route path="/expired"        element={<Tokenexpired />}/>
          <Route path="/forgotpassword" element={<Forgotpassword />}/>
          <Route path="/confirmemail"   element={<ConfirmEmail  />}/>
          <Route path="/resetpassword"  element={<Resetpassword  />}/>
          <Route path="/confirmpayment" element={<ConfirmPayment isAuthenticated={state.isAuthenticated} />}/>
          <Route path="/welcome"        element={state.isAuthenticated ? (<Welcome    isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<Login onLogin={handleLogin} />)}/>
          <Route path="/warning"        element={<Warning />}/>
          <Route path="/rules"          element={<Rules />}/>
          <Route path="/emailsend"      element={state.isAuthenticated ? (<Welcome    isAuthenticated={state.isAuthenticated} currentUser={state.currentUser} />) : (<EmailSend />)} />
          <Route path="*"               element={<NotFound />} />
        </Routes>
      </div>
      <AppFooter isAuthenticated={state.isAuthenticated} />
    </div>
  );

}

export default App;
