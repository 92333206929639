import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getUserProfile, getFlat, getCounter } from '../../util/APIUtils';
import LoadingIndicator from '../../common/LoadingIndicator';
import NotFound from '../../common/NotFound';
import EventBus from "../../common/EventBus";
import ServerError from '../../common/ServerError';
import CheckAuthentication from '../../common/CheckAuthentication';
import { Select, Typography } from "antd";
import './LoginHome.css';
import Table from "../../components/Table";
const { Option } = Select;


const LoginHome = (props) => {
    // const { username } = useParams();
    const {
        isAuthenticated,
        currentUser,
        ...restProps
    } = props;
    const [user, setUser] = useState();
    const [state, setState] = useState(
        {
            flats: [],
            address:'Выбор помещения'
        });

    const loadUserProfile = (username) => {

        if (username !== null) {
            setUser(username);
            setState({
                ...state,
                isLoading: true,
            });
            getFlat()
                .then(response => {
                    setState({
                        ...state,
                        flats: response.listFlat
                    });

                }).catch(error => {
                    if (error.status === 404) {
                        setState({
                            ...state,
                            notFound: true,
                            isLoading: false
                        });
                    } else if (error.status === 406) {
                        setState({
                            ...state,
                            serverError: true,
                            isLoading: false
                        });
                        EventBus.dispatch("handleLogout");
                    } else {
                        setState({
                            ...state,
                            serverError: true,
                            isLoading: false
                        });
                    }
                });
        }
    }

    const handleFlatSelect = (flatId) => {
        let flat = state.flats.find(item => item.flat === flatId);
        
        if (flatId !== null) {
            setState({
                ...state,
                isLoading: true
            })
        }
        getCounter(flatId)
            .then(response => {
                setState({
                    ...state,
                    counters: response.listCounter,
                    isLoading: false,
                    address: flat.address
                });
            }).catch(error => {
                if (error.status === 404) {
                    setState({
                        ...state,
                        notFound: true,
                        isLoading: false
                    });
                } else if (error.status === 406) {
                    setState({
                        ...state,
                        serverError: true,
                        isLoading: false
                    });
                    EventBus.dispatch("handleLogout");
                } else {
                    setState({
                        ...state,
                        serverError: true,
                        isLoading: false
                    });
                }
            });
    }

    const columns = [
        {
            title: 'Ресурс',
            dataIndex: 'nameresource',
            key: 'nameresource',
            editable: false,
        },
        {
            title: 'Счетчик',
            dataIndex: 'namecounter',
            key: 'namecounter',
            editable: false,
        },
        {
            title: 'Заводской номер',
            dataIndex: 'Serialnumber',
            key: 'Serialnumber',
            editable: false,
        },
        {
            title: 'Дата поверки',
            dataIndex: 'datecheck',
            key: 'datecheck',
            editable: false,
            align: 'center'
        },
        {
            title: 'Предыдущие показания',
            dataIndex: 'value',
            key: 'value',
            editable: false,
            inputType: "number",
            align: 'center'
        },
        {
            title: 'Текущие показания',
            dataIndex: 'valuenew',
            key: 'valuenew',
            editable: true,
            inputType: "value",
            align: 'center'
        },
        { dataIndex: "edit", align: 'center', title: "Передать показания", editable: false }
    ];
    
    useEffect(() => {
        loadUserProfile(currentUser.username);
    }, []);

    if (state.isLoading) {
        return <LoadingIndicator />;
    }

    if (state.notFound) {
        return <NotFound />;
    }

    if (state.serverError) {
        return <ServerError />;
    }
    if (!props.isAuthenticated) {
        return <CheckAuthentication {...props} />
    }

    return (
        <div className="home-content">
            {
                user ? (
                    <div>
                        <Typography.Title level={3}>Приборы учета</Typography.Title>
                        <Select
                            className="select"
                            defaultValue="Выбор помещения"
                            style={{ width: 500, marginBottom: 20 }}
                            onChange={handleFlatSelect}
                            value={state.address}
                        >
                            {state.flats.map((flat) => (
                                <Option key={flat.flat} value={flat.flat}>
                                    {flat.address}
                                </Option>
                            ))}
                        </Select>
                        <Table className="home-table" headers={columns} rows={state.counters} />
                    </div>
                ) : null
            }
        </div>
    );

}

export default LoginHome;