import React, { useState} from 'react';
import { resetpasswordreq, checkEmailAvailability } from '../util/APIUtils';
import './resetpassword.css';
import { Link, useSearchParams , useNavigate} from 'react-router-dom';
import {
    EMAIL_MAX_LENGTH,
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH
} from '../constants';
import 'antd/dist/reset.css';
import { Input, Button, notification, Form } from 'antd';
import tokenexpired from '../errorpages/tokenexpired';

const FormItem = Form.Item;

const Resetpassword = (props) => {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState(
        {
            email: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            password: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            passwordconf: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            token: {
                value: ''
            }
        });


    const handleInputChange = (event, validationFun) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        setState({
            ...state,
            [inputName]: {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    const handleSubmit = (values) => {
        // const [searchParams, setSearchParams] = useSearchParams();
        let token = searchParams.get('token');
        // let token = param.get('token');
        // console.log('useParams: ', token);
        setState({
            ...state,
            token: token
        });
        const resetRequest = {
            email: state.email.value,
            confirmPassword: state.passwordconf.value,
            password: state.password.value,
            token: token
        }
        // console.log('Received values of form: ', resetRequest);
        resetpasswordreq(resetRequest)
            .then(response => {
                if (response.success=== "true") {
                notification.success({
                    message: 'Портал ЖКХ',
                    description: "Пароль успешно изменен. Пожалуйста, войдите для продолжения работы!",
                });
                navigate('/login');
            } else {
                notification.error({
                    message: 'Портал ЖКХ',
                    description: response.data,
                });
                navigate('/login');
                }
            }).catch(error => {
                notification.error({
                    message: 'Портал ЖКХ',
                    description: error.data || 'Извините, что-то пошло не так. Попробуйте еще раз!'
                });
            });
    }

    const isFormInvalid = () => {
        return !(state.passwordconf.validateStatus === 'success' &&
            state.email.validateStatus === 'success' &&
            state.password.validateStatus === 'success'
        );
    }
    // Validation Functions

    const validateEmail = (email) => {
        if (!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email не может быть пустым'
            }
        }

        //        const EMAIL_REGEX = RegExp('[^@a-z0-9.]+@[^@ ]+\\.[^@ ]+');
        const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Не корректный Email'
            }
        }

        if (email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }
    const validateEmailAvailability = () => {
        // First check for client side errors in email
        const emailValue = state.email.value;
        const emailValidation = validateEmail(emailValue);

        if (emailValidation.validateStatus === 'error') {
            setState({
                ...state,
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });
            return;
        }

        setState({
            ...state,
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
            .then(response => {
                if (response.data === "true") {
                    setState({
                        ...state,
                        email: {
                            value: emailValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    setState({
                        ...state,
                        email: {
                            value: emailValue,
                            validateStatus: 'error',
                            errorMsg: 'Этот Email не зарегистрирован'
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                setState({
                    ...state,
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            });
    }
    const validatePassword = (password) => {
        if (password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Пароль очень короткий (Менее ${PASSWORD_MIN_LENGTH} символов.)`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Пароль очень длинный (Более ${PASSWORD_MAX_LENGTH} символов.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }
    // useEffect(() => {
    //     console.log('State changed ', state);
    // }, [state]);

    return (
        <div className="signup-container">
            <div className="signup-content">
                <Form onFinish={handleSubmit} className="signup-form"
                // labelCol={{
                //     span: 10,
                // }}
                // wrapperCol={{
                //     span: 20,
                // }}
                >
                    <FormItem
                    // wrapperCol={{
                    //     offset: 10,
                    //     span: 20,
                    // }}
                    >
                        <h1 className="page-title">Изменение пароля</h1>
                    </FormItem>
                    <FormItem
                        // label="Email"
                        hasFeedback
                        validateStatus={state.email.validateStatus}
                        help={state.email.errorMsg}>
                        <Input
                            size="large"
                            name="email"
                            type="email"
                            autoComplete="off"
                            placeholder="Ваш email"
                            value={state.email.value}
                            onBlur={validateEmailAvailability}
                            onChange={(event) => handleInputChange(event, validateEmail)} />
                    </FormItem>
                    <FormItem
                        // label="Пароль"
                        validateStatus={state.password.validateStatus}
                        help={state.password.errorMsg}>
                        <Input
                            size="large"
                            name="password"
                            type="password"
                            autoComplete="off"
                            placeholder="Пароль не менее 6 символов"
                            value={state.password.value}
                            onChange={(event) => handleInputChange(event, validatePassword)} />
                    </FormItem>
                    <FormItem
                        // label="Подтверждение"
                        validateStatus={state.passwordconf.validateStatus}
                        help={state.passwordconf.errorMsg}>
                        <Input
                            size="large"
                            name="passwordconf"
                            type="password"
                            autoComplete="off"
                            placeholder="Подтверждение пароля"
                            value={state.passwordconf.value}
                            onChange={(event) => handleInputChange(event, validatePassword)} />
                    </FormItem>
                    <FormItem
                    // wrapperCol={{
                    //     offset: 10,
                    //     span: 20,
                    // }}
                    >
                        <Button type="primary"
                            htmlType="submit"
                            size="large"
                            className="signup-form-button"
                            disabled={isFormInvalid()}>Изменение пароля</Button>
                        Зарегистрированы? <Link to="/login">Войдите!</Link>
                    </FormItem>
                </Form>
            </div>
        </div>
    );



}
export default Resetpassword;