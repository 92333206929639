import React from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';
import 'antd/dist/reset.css';
import { Button } from 'antd';

const NotFound =(props) => {
        return (
            <div className="page-not-found">
                <h1 className="title">
                    404
                </h1>
                <div className="desc">
                    Страница не найдена.
                </div>
                <Link to="/"><Button className="go-back-btn" type="primary" size="large">Go Back</Button></Link>
            </div>
        );
}

export default NotFound;