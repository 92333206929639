// Production
// export const API_BASE_URL = 'https://api.portal-gkh.ru/api';
export const API_MERCHANT = 'https://api.gateline.ru';
// export const API_MERCHANT = 'https://f3e84be1-e5a7-44d8-9f4c-2fc344f2af6c.mock.pstmn.io';
// export const ClientBackUrl = 'https://lk.portal-gkh.ru/confirmpayment';
// export const NotificationURL = 'https://api.portal-gkh.ru/api/auth/payment';
// export const Site = 'portalgkh_api';


// Test
export const API_BASE_URL = 'https://api.portal-gkh.ru/api';
// // export const API_BASE_URL = 'https://testapi.portal-gkh.ru/api';
// export const API_MERCHANT = 'https://testgate.vseplatezhi.ru';
// export const ClientBackUrl = 'http://localhost:3000/confirmpayment';
// export const NotificationURL = 'https://testapi.portal-gkh.ru/api/auth/payment';
// export const API_MERCHANT = 'https://api.sandbox.gateline.net:18210';




export const ICON_SOURCE = 'https://testgate.vseplatezhi.ru';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRATION = 'expitration';
export const DEVICE_ID = 'device';

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
export const TOKEN_MIN_LENGTH = 0;
export const TOKEN_MAX_LENGTH = 12;

export const visa = ICON_SOURCE + "/resources/images/cards/light/visa-d7e7e9e8268652febb4f9a897882db1c.svg";
export const mastercard = ICON_SOURCE + "/resources/images/cards/light/mastercard-8d3cabb6a9161487f3c3a2041f851ee2.svg";
export const mir = ICON_SOURCE + "/resources/images/cards/light/mir-d9b6546199fc42753ad7fc929cabb59f.svg";

export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
