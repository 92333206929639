import React, { useState } from "react";
import Moment from "moment";
import {
  Input,
  InputNumber as AntInputNumber,
  DatePicker as AntDatePicker, Tooltip, Form
} from "antd";
import NumberFormat from "react-number-format";

const InputNumber = (props) => {
  const { value, handleInputChange, ...restProps } = props;
  const placeholder = "Внесите показания";
  return (
    <AntInputNumber
      style={{ width: 240 }}
      value={value}
      //     formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // Deal with currency here. Default to $
      parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // What it means?
      //     precision={4}
      placeholder={placeholder}
      decimalSeparator="."
      size="small"
      step={1}
      onChange={handleInputChange}
      {...restProps}
    />
  );
};
const InputValue = (props) => {
  const { value, handleInputChange, ...restProps } = props;
  const placeholder = "Внесите показания";
  return (
    <AntInputNumber
      style={{ width: 240 }}
      value={value}
      //     formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // Deal with currency here. Default to $
      parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // What it means?
      //     precision={4}
      placeholder={placeholder}
      decimalSeparator="."
      size="small"
      step={1}
      onChange={handleInputChange}
      {...restProps}
    />
  );
};
const InputSumm = (props) => {
  const { value, handleInputChange, ...restProps } = props;
  // const placeholder = "Внесите показания";
  return (
    <Input
      style={{ width: 240 }}
      value={value}
      //     formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // Deal with currency here. Default to $
      parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // What it means?
      //     precision={4}
      // placeholder={placeholder}
      decimalSeparator="."
      size="small"
      step={1}
      onChange={handleInputChange}
      {...restProps}
    />
  );
};
const DatePicker = (props) => {
  const { value, handleInputChange, ...restProps } = props;
  const placeholder = "Select year";

  return (
    <AntDatePicker
      picker="month"
      size="small"
      allowClear="true"
      placeholder={placeholder}
      format="YYYY-MM"
      value={value}
      onChange={(e) => handleInputChange(e.format("MMM YYYY"))}
      {...restProps}
    />
  );
};
// const fnum = (text) => new Number(text).toFixed(2);
const formatNumber = (value) => new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value);
const NumericInput = (props) => {
  const { value, handleInputChange, ...restProps } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d{1,6}(\.\d{0,2})?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      handleInputChange(inputValue);
    }
  }; // '.' at the end or only '-' in the input box.

  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    handleInputChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };

  const title = value ? (
    <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
  ) : (
    'Input a number'
  );
  return (
    // <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
    <Input className="numeric-input"
      // {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Введите сумму"
      maxLength={10}
      value={value}
    />
    // </Tooltip>
  );
};
const ValueInput = (props) => {
  const { value, handleInputChange, ...restProps } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d{1,6}(\.\d{0,3})?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      handleInputChange(inputValue);
    }

  }; // '.' at the end or only '-' in the input box.

  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    handleInputChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };


  return (
    // <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
    <Input className="numeric-input"
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Введите показания"
      maxLength={10}
      value={value}
    />
    // </Tooltip>

  );
};



const InputNode = ({ inputType, value, ...restProps }) => {
  const newValue = inputType === "date" ? Moment(value) : value;

  switch (inputType) {
    case "text":
      return <Input value={newValue} {...restProps} />;
    case "number":
      return <InputNumber value={newValue} {...restProps} />;
    case "value":
      return <ValueInput value={newValue} {...restProps} />;
    case "summ":
      return <NumericInput value={newValue} {...restProps} />;
    case "date":
      return <DatePicker value={newValue} {...restProps} />;
    default:
      return <Input value={newValue} {...restProps} />;
  }
};

export default InputNode;
