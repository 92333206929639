import React from 'react';
import './Rules.css'
import 'antd/dist/reset.css';
import { Typography } from 'antd';
const Rules = (props) => {
return (
    <>
	    <Typography>
<div className="page-header">
    <h3>Пользовательское соглашение сервисов ООО "ПИРЦ"</h3>
</div>
<div className="clearfix" style={{textAlign : 'left', margin:'20px'}}>
<p>
 <b>1. Общие положения</b>
					</p>
					<p>
						 ООО "ПИРЦ" предлагает пользователю сети Интернет (далее – Пользователь) - использовать свои сервисы на условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение», «ПС»). Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями.
					</p>
					<p>
 <b>2. Регистрация Пользователя. Учетная запись Пользователя</b>
					</p>
					<p>
						 2.1. Для того чтобы воспользоваться сервисами ООО "ПИРЦ", Пользователю необходимо пройти процедуру регистрации, в результате которой для Пользователя будет создана учетная запись.
					</p>
					<p>
						 2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии.
					</p>
					<p>
						 2.3. Персональная информация Пользователя, содержащаяся в учетной записи Пользователя, хранится и обрабатывается ООО "ПИРЦ" в соответствии с условиями Политики обработки песональных данных.
					</p>
					<p>
						 2.4. Логин и пароль для доступа к учетной записи Пользователя. При регистрации Пользователь самостоятельно вводит адрес электронной почты, логин и пароль для доступа к учетной записи.
					</p>
					<p>
						 2.5. Пользователь самостоятельно несет ответственность за безопасность (устойчивость к угадыванию) выбранного им пароля, а также самостоятельно обеспечивает конфиденциальность своего пароля. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием сервисов под учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях . При этом все действия в рамках или с использованием сервисов ООО "ПИРЦ" под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением случаев, когда Пользователь, уведомил ООО "ПИРЦ" о несанкционированном доступе к сервисам с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля.
					</p>
					<p>
						 2.6. Пользователь обязан немедленно уведомить о любом случае несанкционированного (не разрешенного Пользователем) доступа к сервисам ООО "ПИРЦ" с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля. В целях безопасности, Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой сессии работы с сервисами. ООО "ПИРЦ" не отвечает за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем положений этой части Соглашения.
					</p>
					<p>
						 2.7. Прекращение регистрации. ООО "ПИРЦ" вправе заблокировать или удалить учетную запись Пользователя, а также запретить доступ с использованием какой-либо учетной записи к определенным сервисам ООО "ПИРЦ", и удалить без объяснения причин, в том числе в случае нарушения Пользователем условий Соглашения или условий иных документов, а также в случае неиспользования соответствующего сервиса, в частности:
					</p>
					<p>
						 2.8. Удаление учетной записи Пользователя.
					</p>
					<p>
						 2.9. Пользователь вправе в любой момент удалить свою учетную запись в сервисах ООО "ПИРЦ" или прекратить ее действие в отношении некоторых из них, воспользовавшись соответствующей функцией в персональном разделе. Удаление регистрации в Личном кабинете производится на основании письма Пользователя в адрес ООО "ПИРЦ".
					</p>
					<p>
 <b>3. Общие положения об использовании и хранении</b>
					</p>
					<p>
						 3.1. ООО "ПИРЦ" вправе устанавливать ограничения в использовании сервисов для всех Пользователей, либо для отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка, на котором предоставляется сервис и т.д.), в том числе: наличие/отсутствие отдельных функций сервиса. ООО "ПИРЦ" может запретить автоматическое обращение к своим сервисам, а также прекратить прием любой информации, сгенерированной автоматически (например, почтового спама).
					</p>
					<p>
						 3.2. ООО "ПИРЦ" вправе посылать своим пользователям информационные сообщения.
					</p>
					<p>
 <b>4. Контент Пользователя</b>
					</p>
					<p>
						 4.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем контента требованиям действующего законодательства, включая ответственность перед третьим лицами в случаях, когда размещение Пользователем того или иного контента или содержание контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им нематериальные блага.
					</p>
					<p>
						 4.2. Пользователь признает и соглашается с тем, что ООО "ПИРЦ" не обязан просматривать контент любого вида, размещаемый и/или распространяемый Пользователем посредством сервисов ООО "ПИРЦ".
					</p>
					<p>
						 4.3. Пользователь осознает и соглашается с тем, что технология работы сервисов может потребовать копирование (воспроизведение) контента Пользователя ООО "ПИРЦ", а также переработки его ООО "ПИРЦ" для соответствия техническим требованиям того или иного сервиса.
					</p>
					<p>
 <b>5. Условия использования сервисов ООО "ПИРЦ"</b>
					</p>
					<p>
						 5.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Сервиса, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сервиса.
					</p>
					<p>
						 5.2. При использовании сервисов ООО "ПИРЦ" Пользователь не вправе:
					</p>
					<p>
						 5.2.1. посылать, передавать или любым другим способом размещать и/или распространять контент, который является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ или иного оружия;
					</p>
					<p>
						 5.2.2. нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вреда в любой форме;
					</p>
					<p>
						 5.2.3. выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников ООО "ПИРЦ", за модераторов форумов, за владельца сайта, а также применять любые другие формы и способы незаконного представительства других лиц в сети, а также вводить пользователей или ООО "ПИРЦ" в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов;
					</p>
					<p>
						 5.2.4. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;
					</p>
					<p>
						 5.2.5. загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа логины, пароли.
					</p>
					<p>
						 5.2.6. несанкционированно собирать и хранить персональные данные других лиц;
					</p>
					<p>
						 5.2.7. нарушать нормальную работу веб-сайта и сервисов ООО "ПИРЦ";
					</p>
					<p>
						 5.2.8. размещать ссылки на ресурсы сети, содержание которых противоречит действующему законодательству РФ;
					</p>
					<p>
						 5.2.9. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;
					</p>
					<p>
						 5.2.10. другим образом нарушать нормы законодательства, в том числе нормы международного права.
					</p>
					<p>
 <b>6. Исключительные права на содержание сервисов и контент</b>
					</p>
					<p>
						 6.1. Использование контента, а также каких-либо иных элементов сервисов возможно только в рамках функционала, предлагаемого тем или иным сервисом. Никакие элементы содержания сервисов ООО "ПИРЦ", а также любой контент, размещенный на сервисах ООО "ПИРЦ", не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные законодательством.
					</p>
					<p>
 <b>7. Сайты и контент третьих лиц</b>
					</p>
					<p>
						 7.1. Сервисы ООО "ПИРЦ" могут содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и их контент не проверяются на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). ООО "ПИРЦ" не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием сервисов, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем.
					</p>
					<p>
 <b>8. Иные положения</b>
					</p>
					<p>
						 8.1. Настоящее Соглашение представляет собой договор между Пользователем и ООО "ПИРЦ" относительно порядка использования сервисов.
					</p>
					<p>
						 8.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство» понимается как законодательство Российской Федерации, так и законодательство места пребывания Пользователя.
					</p>
					<p>
						 8.3. Ввиду безвозмездности услуг, оказываемых в рамках настоящего Соглашения, нормы о защите прав потребителей, предусмотренные законодательством Российской Федерации, не могут быть к применимыми к отношениям между Пользователем и ООО "ПИРЦ".
					</p>
					<p>
						 8.4. Ничто в Соглашении не может пониматься как установление между Пользователем и ООО "ПИРЦ" агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.
					</p>
					<p>
						 8.5. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.
					</p>
					<p>
						 8.6. Бездействие со стороны ООО "ПИРЦ" в случае нарушения Пользователем либо иными пользователями положений Соглашений не лишает ООО "ПИРЦ" права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа ООО "ПИРЦ" от своих прав в случае совершения в последующем подобных либо сходных нарушений.
					</p>
					<p>
						 8.7. Настоящее Соглашение составлено на русском языке.
					</p>


</div>

</Typography>
</>
);
}
export default Rules;