
export const getFunctionalColumns = (rawColumns, renderers, func = {}) => {
  const { isEditing, currentRowValues, handleInputChange } = func || {};

  return (
    rawColumns &&
    rawColumns.map((col) => {
      const rendererKeys = Object.keys(renderers);
      let editFunc = {};
      let renderFunc = {};

      if (col.editable) {
        editFunc = {
          onCell: (row) => ({
            row,
            dataIndex: col.dataIndex,
            title: col.title,
            editable: col.editable,
            inputType: col.inputType,
            editing: isEditing(row.key),
            currentRowValues,
            handleInputChange
          })
        };
      }

      if (rendererKeys.includes(col.dataIndex)) {
        renderFunc = {
          render: renderers[col.dataIndex]
        };
      }

      const functionalColumns = { ...col, ...editFunc, ...renderFunc };

      return functionalColumns;
    })
  );
};
