import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import './tokenexpired.css';
import 'antd/dist/reset.css';


const tokenexpired =(props) => {
 
        return (
            <div  className="expired-container">
             <h1 style={{textAlign:"center"}}>Token expired</h1>   
            <p style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </p>
            </div>
        );
    
}

export default tokenexpired;