import React, { useState } from 'react';
import './AppHeader.css';
import { Layout, Menu } from 'antd';
import { HomeOutlined, UserOutlined, DownOutlined, MenuFoldOutlined,DashboardOutlined , LogoutOutlined , ContainerOutlined, UserAddOutlined  } from '@ant-design/icons';
import logo from '../logo-text_white_60.png';

const Header = Layout.Header;



const AppHeader = (prop) => {
  const [current, setCurrent] = useState();
  const {
    isAuthenticated,
    currentUser,
    onLogout,
    onClick,
    ...porps
  } = prop;
  let items;
  if (currentUser) {
    items = [
      {
        label: '',
        key: "/",
        icon: <HomeOutlined />
      },
      {
        label: '',
        key: 'submenu',
        icon: <MenuFoldOutlined />,
        children: [
          {
            label: 'Платежные документы',
            key: '/amount',
            icon:<ContainerOutlined />
          },
          {
            label: 'Приборы учета',
            key: '/counters',
            icon: <DashboardOutlined />
          },
          {
            label: 'Добавить помещение',
            key: '/addtoken',
            icon: <UserAddOutlined />
          },
          {
            label: 'Профиль',
            key: '/users',
            icon: <UserOutlined />
          },
          {
            type: 'divider',
            key: 'divider'
          },
          {
            label: 'Выход',
            key: 'logout',
            icon: <LogoutOutlined />
          }
        ]

      }
    ];
  } else {
    items = [
      {
        label: 'Вход',
        key: '/login'
      },
      {
        label: 'Регистрация',
        key: '/signup'
      }
    ];
  }
  return (
    <Header className="app-header">
      <div className="container">
        <div className="app-title" >

          <a className='logo' href="http://portal-gkh.ru"><img src={logo} alt='Портал ЖКХ' /></a>
        </div>

        <Menu
          mode="horizontal"
          className="app-menu"
          selectedKeys={[current]}
          style={{ lineHeight: '84px'}}
          onClick={onClick}
          items={items}
        >
        </Menu>

      </div>
    </Header>
  );

}
export default AppHeader;