import React, { Component } from 'react';
import {    
  Navigate
} from "react-router-dom";

const CheckAuthentication =(props) =>{
        return (
          props.username === null | props.currentUser === null && props.isAuthenticated === false ? <Navigate
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />:  null  
        );
}

export default CheckAuthentication;
