import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import ButtonGroupTableEdit from "./Button";
import ButtonPay from "./ButtonPay";


export const getCellRenderers = (eventHandlers, func = {}) => {
  const { isEditing } = func || {};

  const sort = {
    sort: () => <MenuOutlined />
  };

  const edit = {
    edit: (_, row) => (
      <ButtonGroupTableEdit
        editing={isEditing(row.key)}
        eventHandlers={eventHandlers}
        rowKey={row.key}
        disabled={(!row.editable)} />
    )
  };
  const bpay = {
    bpay: (_, row) => (
      <ButtonPay
        editing={isEditing(row.key)}
        eventHandlers={eventHandlers}
        rowKey={row.key}
        disabled={!(row.amount > 0) || (row.payment === 0)} />
    )
  };
  const renderers = { ...sort, ...edit, ...bpay };

  return renderers;
};
