import React from "react";
import Styled from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined, CheckCircleTwoTone , CloseCircleTwoTone, EditTwoTone, CreditCardOutlined 
} from "@ant-design/icons";
import {
  Select,
  Row,
  Col,
  List,
  Button,
  Divider,
  Spin,
  Input,
  Typography,
  Form,
  Empty,
  Modal,InputNumber,Popconfirm, notification
} from "antd";

const ButtonGroupTableEditContainer = Styled.div`
  width: 65px;
  margin: 0 5px;
  display: flex;
  justify-content: space-between;
`;

const ButtonPay = ({ editing,eventHandlers, rowKey, disabled }) => {
  const { payRow, editRow, deleteRow, saveRow, cancelRow } = eventHandlers;
        //  console.log('ButtonPay  ', editing); 
    return !editing ? (
    // <ButtonGroupTableEditContainer>
      <Button type="primary" icon={<CreditCardOutlined />} disabled={disabled} onClick={() => editRow(rowKey)} > Оплатить </Button>
          // <Button type="primary" icon={<CreditCardOutlined />} disabled={false} onClick={() => editRow(rowKey)} > Оплатить </Button>
    // </ButtonGroupTableEditContainer>
 ): (
  <ButtonGroupTableEditContainer>
    <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor="#52c41a" onClick={() => payRow(rowKey)} />
    <CloseCircleTwoTone style={{ fontSize: '22px' }} twoToneColor="#eb2f96" onClick={() => cancelRow()} />
  </ButtonGroupTableEditContainer>
);
};

export default ButtonPay;
