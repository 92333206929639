import React from 'react';
import './EmailSend.css';
import { Link } from 'react-router-dom';
import 'antd/dist/reset.css';
import { Button } from 'antd';

const EmailSend =(props) => {
        return (
            <div className="email-send">
                <h5 className="title">
                    Подтвердите электронный адрес
                </h5>
                <div className="desc">
                    На Ваш электронный адрес отправлено письмо с подтверждением регистрации. Необходимо открыть это письмо и подтвердить регистрацию.
                </div>
                <Link to="/"><Button className="go-back-btn" type="primary" size="large">Перейти на страницу входа</Button></Link>
            </div>
        );
}

export default EmailSend;