
export function formatDate(dateString) {
    const date = new Date(dateString);

    const monthNames = [
      "Январь", "Февраль", "Март",
      "Апрель", "Май", "Июнь", "ИЮюль",
      "Август", "Сентябрь", "Октябрь",
      "Ноябрь", "Декабрь"
    ];
  
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return monthNames[monthIndex] + ' ' + year;
}
  


export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug", 
    "Sep", "Oct", "Nov", "Dec"
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes();
}  

export function getMonthNames(mm, locale = 'ru', format = 'long') {
  const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });

   const date= new Date(`2017-${mm}-01T00:00:00+00:00`);
  
  return formatter.format(date);
}

export function getPeriodNames(period, locale = 'ru') { 
   const date= new Date((period-period%100)/100,period%100-1); 
  return  date.toLocaleString(locale, { month: "long" }) + ' ' + date.getFullYear();
}
