import React from "react";
import InputNode from "./InputNode";

const Cell = (props) => {
  const {
    row,
    dataIndex,
    title,
    editable,
    inputType,
    editing,
    children,
    currentRowValues,
    handleInputChange,
    ...restProps
  } = props;

  let childNode = children;
  if (editable) {
    childNode = !editing ? (
      <div style={{ padding: "5px 12px" }}>{childNode}</div>
    ) : (
      <InputNode
        style={{ margin: 0 }}
        inputType={inputType}
        value={currentRowValues[dataIndex]}
        handleInputChange={(value) => {
          handleInputChange(value, dataIndex);
        }}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default Cell;
