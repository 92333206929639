import React, { Component, useState, useEffect } from 'react';
import { addtoken, checkTokenAvailability } from '../../util/APIUtils';
import './Addtoken.css';
import { Link, useNavigate } from 'react-router-dom';
import {
    TOKEN_MIN_LENGTH, TOKEN_MAX_LENGTH
} from '../../constants';
import 'antd/dist/reset.css';
import { Input, Button, notification, Form, Tooltip, Typography, Space, Checkbox } from 'antd';

const FormItem = Form.Item;

const Addtoken = (props) => {
    const {
        isAuthenticated,
        currentUser,
        ...restProps
    } = props;
    let navigate = useNavigate();
    const [user, setUser] = useState();
    const [chect, setChect] = useState();
    const [state, setState] = useState(
        {
            token: {
                value: '',
                validateStatus: ''

            },
            agreement: {
                value: false
            }
        });

    const handleInputChange = (event, validationFun) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        setState({
            ...state,
            [inputName]: {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }
    const handleCheckChange = (event, validationFun) => {
        // console.log('CheckEvent:', event);
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.checked;
        setChect(inputValue);
        setState({
            ...state,
            [inputName]: {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }
    const handleSubmit = (values) => {
        if (!isFormInvalid()) {
            //     notification.error({
            //         message: 'Портал ЖКХ',
            //         description: error.message || 'Неправильный код. Попробуйте еще раз!'
            //     });  
            // }
            // else {
            // console.log('Success:', values);
            const addtokenRequest = state.token.value;
            // console.log('Received values of form: ', signupRequest);
            addtoken(addtokenRequest)
                .then(response => {
                    notification.success({
                        message: 'Портал ЖКХ',
                        description: response.data,
                    });
                    navigate(`/welcome`);
                }).catch(error => {
                    notification.error({
                        message: 'Портал ЖКХ',
                        description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
                    });
                });
        }
        else {
            notification.error({
                message: 'Портал ЖКХ',
                description: 'Неправильный код. Попробуйте еще раз!'
            });
        }
    }
    const validateAgreement = (agreement) => {
        // console.log('Checked:', agreement);
        if (agreement) {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        } else {
            return {
                validateStatus: 'error',
                errorMsg: null,
            };
        }

    }
    const isFormInvalid = () => {
        return !(
            // state.agreement.value &&
            chect &&
            state.token.validateStatus === 'success'
        );
    }

    // Validation Functions

    const validateToken = (token) => {
        if (token.length < TOKEN_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Неверный код`
            }
        } else if (token.length > TOKEN_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Неверный код`
            }
        } else {
            return {
                validateStatus: null,
                errorMsg: null
            }
        }
    }



    const validateTokenAvailability = () => {
        // First check for client side errors in email
        const tokenValue = state.token.value;
        const tokenValidation = validateToken(tokenValue);

        if (tokenValidation.validateStatus === 'error') {
            setState({
                ...state,
                token: {
                    value: tokenValue,
                    ...tokenValidation
                }
            });
            return;
        }

        setState({
            ...state,
            token: {
                value: tokenValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });
        // console.log('validateTokenAvailability1',tokenValue);
        checkTokenAvailability(tokenValue)
            .then(response => {
                if (response.data=== "true") {
                    setState({
                        ...state,
                        token: {
                            value: tokenValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    setState({
                        ...state,
                        token: {
                            value: tokenValue,
                            validateStatus: 'error',
                            errorMsg: 'Код не верен или просрочен'
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                setState({
                    ...state,
                    token: {
                        value: tokenValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            });
    }
    // useEffect(() => {
    //     console.log('Store changed ', state);
    // }, [state]);
    return (
        <div className="signup-container">

            <div className="signup-content">
                <Typography.Title level={3}> Добавить помещение</Typography.Title>
                <Form onFinish={handleSubmit} className="signup-form"
                //   labelCol={{
                //         span: 5,
                //     }}
                //     wrapperCol={{
                //         span: 20,
                //     }}
                >
                    <FormItem
                        //                          label="Код" 
                        style={{ marginBottom: "0px" }}>
                        <Space style={{ float: "left" }}>
                            <FormItem
                                style={{ width: "150px" }}
                                hasFeedback
                                validateStatus={state.token.validateStatus}
                                help={state.token.errorMsg}>
                                <Input
                                    size="large"
                                    name="token"
                                    autoComplete="off"
                                    placeholder="Код регистрации"
                                    value={state.token.value}
                                    onBlur={validateTokenAvailability}
                                    onChange={(event) => handleInputChange(event, validateToken)} />
                            </FormItem>
                            <Tooltip title="Код указан в квитанции">
                                <Typography.Text >Где взять?</Typography.Text>
                            </Tooltip>
                        </Space>
                    </FormItem>
                    <FormItem
                        // validateStatus={state.agreement.validateStatus}
                        // help={state.agreement.errorMsg}
                        >
                        <Checkbox
                            name="agreement"
                            // checked = {this.state.agreement.value} 
                            onChange={(event) => handleCheckChange(event, validateAgreement)}
                        >
                            Настоящей регистрацией Вы принимаете условия <a href="/rules">Пользовательского соглашения</a>  на условиях и для целей, определённых <a href="/warning">Политикой обработки песональных данных</a> и даёте свое согласие ООО "ПИРЦ" на обработку своих персональных данных, в соответствии с Федеральным законом от 27.07.2006 г. № 152 - ФЗ "О персональных данных".
                        </Checkbox>

                    </FormItem>
                    <FormItem
                    //   label=" " 
                    // wrapperCol={{
                    //     offset: 5,
                    //     span: 20,
                    //     }}
                    >

                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="signup-form-button button"
                            disabled={isFormInvalid()}
                        >
                            Добавить помещение
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    );




}

export default Addtoken;