import React, { useState , useEffect} from "react";
import { Table as AntTable, notification } from "antd";
import Cell from "./Cell";
import EventBus from '../common/EventBus';
import { setCountVal, makePayment, makePayment1 } from '../util/APIUtils';
import { getFunctionalColumns } from "./getFunctionalColumns";
import { getCellRenderers } from "./getCellRenderers";
import '../App.css'

const Table = ({ headers, rows}) => {
  const [editingKey, setEditingKey] = useState();
  const [rowList, setRowList] = useState(rows);
  const [currentRowValues, setCurrentRowValues] = useState();
  useEffect(() => {
    if (rows) {
      setRowList(rows);
    }
}, [rows]); 
let locale = { emptyText: 'Нет данных'}
  // Event Handlers+
  const isEditing = (rowKey) => rowKey === editingKey;

  const handleInputChange = (value, dataIndex) => {
    // const fnum=(value)=>  new Number(value).toFixed(2);
    setCurrentRowValues((old) => ({ ...old, [dataIndex]: value }));
  };

  const editRow = (rowKey) => {
    setEditingKey(rowKey);
    setCurrentRowValues({ ...rowList.find((row) => row.key === rowKey) });
  };

  const deleteRow = (rowKey) => {
    const newRowList = rowList.filter((row) => row.key !== rowKey);
    setRowList(newRowList);
  };

  const saveRow = (rowKey) => {
    const rowIndex = rowList.findIndex((row) => row.key === rowKey);
    const newRowList = [...rowList];

    if (rowIndex > -1) {
      const row = rowList[rowIndex];
      newRowList.splice(rowIndex, 1, { ...row, ...currentRowValues });
      setRowList(newRowList);
      setEditingKey();
      setCurrentRowValues();
      const countvalrequest={
        counter:row.key,
        value: currentRowValues.valuenew
      }

    // EventBus.dispatch("handleWritevalue",countvalrequest);
    setCountVal(countvalrequest)
    .then(response => {

      notification.success({
        message: 'Портал ЖКХ',
        description: response.data
      });
    }).catch(error => {
      if (error.status === 404) {
        notification.error({
          message: 'Портал ЖКХ',
          description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
        });    
      } else if (error.status === 406) {
        EventBus.dispatch("handleLogout");
      } else {
        notification.error({
          message: 'Портал ЖКХ',
          description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
        });
      }
    });
    } else {
      newRowList.push(currentRowValues);
      setRowList(newRowList);
      setEditingKey();
    }
  };
  const payRow = (rowKey) =>{
    const rowIndex = rowList.findIndex((row) => row.key === rowKey);
    const newRowList = [...rowList];
    const row = rowList[rowIndex];
    newRowList.splice(rowIndex, 1, { ...row, ...currentRowValues });
    setRowList(newRowList);
    setEditingKey();
    setCurrentRowValues();
    // console.log('currentRowValues.amount:  ', currentRowValues.amount);
    const request={
      amount : Number(currentRowValues.amount).toFixed(2),
      description : currentRowValues.description,
      terminal : currentRowValues.terminal,
  } 
    // console.log('crequest:  ', request);
    // Payment(request);


  }
  const cancelRow = () => {
    setEditingKey();
  };

  // Transform Helpers
  const eventHandlers = { editRow, cancelRow, saveRow, deleteRow, payRow };
  const renderers = getCellRenderers(eventHandlers, { isEditing });
  const functionalColumns = getFunctionalColumns(headers, renderers, {
    isEditing,
    currentRowValues,
    handleInputChange
  });

  // View
  const components = {
    body: {
      cell: Cell
    }
  };


  return (
    <AntTable
      rowClassName={() => "editable-row"}
      components={components}
      columns={functionalColumns}
      dataSource={rowList}
      bordered={false}
      pagination={false}
      locale={locale}
      className = 'table'
    />
  );
};

export default Table;
