import React from 'react'
import { Layout, Typography } from 'antd';
import './AppFooter.css';
import { visa, visasecure, mastercard, mastercardsecure, maestro, mir, mirsecure, unionpay, pci } from './../constants/index'
import { Link } from 'react-router-dom';
import 'antd/dist/reset.css';
const Footer = Layout.Footer;

const AppFooter = () => {


    return (

        <Footer className="app-footer">
            <div className="logos_bar">
                <img src={visa} className="logos" alt="" />
                <img src={mastercard} className="logos" alt="" />
                <img src={mir} className="logos" alt="" />
            </div>
            <div className="app-rights">
                <Typography className="app-rights">All Rights Reserved 2017-2025 ООО "ПИРЦ" </Typography>
                <Typography className="app-rights"><Link to={`/warning`} style={{ marginLeft: '20px' }}>Политика обработки персональных данных</Link></Typography>
                <Typography className="app-rights"><Link to={`/rules`} style={{ marginLeft: '20px' }}>Пользовательское соглашение</Link></Typography>
            </div>
        </Footer>

    )

}
export default AppFooter;