import React, { Component, useState } from 'react';
import { signup, checkUsernameAvailability, checkEmailAvailability, checkTokenAvailability } from '../../util/APIUtils';
import './Signup.css';
import { Link, useNavigate } from 'react-router-dom';
import {
    NAME_MIN_LENGTH, NAME_MAX_LENGTH,
    USERNAME_MIN_LENGTH, USERNAME_MAX_LENGTH,
    EMAIL_MAX_LENGTH,
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH,
    TOKEN_MIN_LENGTH, TOKEN_MAX_LENGTH,  EMAIL_REGEX 
} from '../../constants';
import 'antd/dist/reset.css';
import { Input, Button, notification, Form, Tooltip, Typography, Space, Checkbox } from 'antd';

const FormItem = Form.Item;

const Signup = (prors) => {
    let navigate = useNavigate();
    // const history = useHistory();
    const [state, setState] = useState(
        {
            name: {
                value: ''
            },
            username: {
                value: '',
                validateStatus: ''
            },
            email: {
                value: ''
            },
            password: {
                value: ''
            },
            token: {
                value: ''
            },
            registerAsAdmin: {
                value: false
            },
            agreement: {
                value: false
            }
        });


    const handleInputChange = (event, validationFun) => {
        // console.log('Event:', event);
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        setState({...state,
            [inputName]: {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    const handleCheckChange = (event, validationFun) => {
        // console.log('CheckEvent:', event);
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.checked;

        setState({...state,
            [inputName]: {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }
    const handleSubmit = (values) => {

        const signupRequest = {
            email: state.email.value,
            username: state.username.value,
            password: state.password.value,
            regtoken: state.token.value,
            registerAsAdmin: state.registerAsAdmin.value
        };

        signup(signupRequest)
            .then(response => {
                notification.success({
                    message: 'Портал ЖКХ',
                    description: "Спасибо за регистрацию! На Ваш email отправлено письмо с подверждением регистрации.",
                });
                navigate("/emailsend");
            }).catch(error => {
                notification.error({
                    message: 'Портал ЖКХ',
                    description: error.message || 'Извините, что-то пошло не так. Попробуйте еще раз!'
                });
            });
    }

    const isFormInvalid = () => {
        return !(
            state.agreement.validateStatus === 'success' &&
            state.username.validateStatus === 'success' &&
            state.email.validateStatus === 'success' &&
            state.token.validateStatus === 'success' &&
            state.password.validateStatus === 'success'
        );
    }
    // Validation Functions
    const validateAgreement = (agreement) => {
        // console.log('Checked:', agreement);
        if (agreement) {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        } else {
            return {
                validateStatus: 'error',
                errorMsg: null,
            };
        }

    }
    const validateName = (name) => {
        if (name.length < NAME_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Name is too short (Minimum ${NAME_MIN_LENGTH} characters needed.)`
            }
        } else if (name.length > NAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }

    const validateEmail = (email) => {
        if (!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email не может быть пустым'
            }
        }

        //        const EMAIL_REGEX = RegExp('[^@a-z0-9.]+@[^@ ]+\\.[^@ ]+');
        // const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Не корректный Email'
            }
        }

        if (email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }

    const validateUsername = (username) => {
        const USERNAME_REGEX = /^[A-Za-z0-9]+$/;

        if (!USERNAME_REGEX.test(username)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Допускаются только английские буквы и цифры'
            }
        }

        if (username.length < USERNAME_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Логин очень короткий (Не менее ${USERNAME_MIN_LENGTH} символов.)`
            }
        } else if (username.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Логин очень длинный (Не более ${USERNAME_MAX_LENGTH} символов.)`
            }
        } else {
            return {
                validateStatus: null,
                errorMsg: null
            }
        }
    }
    const validateToken = (token) => {
        if (token.length < TOKEN_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Неверный код`
            }
        } else if (token.length > TOKEN_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Неверный код`
            }
        } else {
            return {
                validateStatus: null,
                errorMsg: null
            }
        }
    }
    const validateUsernameAvailability = () => {
        // First check for client side errors in username
        const usernameValue = state.username.value;
        const usernameValidation = validateUsername(usernameValue);

        if (usernameValidation.validateStatus === 'error') {
            setState({...state,
                username: {
                    value: usernameValue,
                    ...usernameValidation
                }
            });
            return;
        }

        setState({...state,
            username: {
                value: usernameValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkUsernameAvailability(usernameValue)
            .then(response => {
                if (response.data === "false") {
                    setState({...state,
                        username: {
                            value: usernameValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    setState({...state,
                        username: {
                            value: usernameValue,
                            validateStatus: 'error',
                            errorMsg: 'Этот пользователь уже зарегистрирован'
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                setState({
                    username: {...state,
                        value: usernameValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            });
    }

    const validateEmailAvailability=() =>{
        // First check for client side errors in email
        const emailValue = state.email.value;
        const emailValidation = validateEmail(emailValue);

        if (emailValidation.validateStatus === 'error') {
            setState({...state,
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });
            return;
        }

        setState({...state,
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
            .then(response => {
                if (response.data === "false") {
                    setState({...state,
                        email: {
                            value: emailValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    setState({...state,
                        email: {
                            value: emailValue,
                            validateStatus: 'error',
                            errorMsg: 'Этот Email уже зарегистрирован'
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                setState({...state,
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            });
    }
    const validateTokenAvailability=()=> {
        // First check for client side errors in email
        const tokenValue = state.token.value;
        const tokenValidation = validateToken(tokenValue);

        if (tokenValidation.validateStatus === 'error') {
            setState({...state,
                token: {
                    value: tokenValue,
                    ...tokenValidation
                }
            });
            return;
        }

        setState({...state,
            token: {
                value: tokenValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });
        // console.log('validateTokenAvailability1',tokenValue);
        checkTokenAvailability(tokenValue)
            .then(response => {
                if (response.data=== "true") {
                    setState({...state,
                        token: {
                            value: tokenValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    setState({...state,
                        token: {
                            value: tokenValue,
                            validateStatus: 'error',
                            errorMsg: 'Код не верен или просрочен'
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                setState({...state,
                    token: {
                        value: tokenValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            });
    }
    const validatePassword = (password) => {
        if (password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Слишком короткий пароль (Не менее ${PASSWORD_MIN_LENGTH} символов.)`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Слишком длинный пароль (Не более ${PASSWORD_MAX_LENGTH} символов.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }

    return (
        <div className="signup-container">
            <Typography className="page-title">Регистрация</Typography>
            <div className="signup-content">
                <Form onFinish={handleSubmit} className="signup-form"
                //   labelCol={{
                //         span: 5,
                //     }}
                //     wrapperCol={{
                //         span: 20,
                //     }}
                >
                    <FormItem
                        //                          label="Код" 
                        style={{ marginBottom: "0px" }}>
                        <Space style={{ float: "left" }}>
                            <FormItem
                                style={{ width: "150px" }}
                                hasFeedback
                                validateStatus={state.token.validateStatus}
                                help={state.token.errorMsg}>
                                <Input
                                    size="large"
                                    name="token"
                                    autoComplete="off"
                                    placeholder="Код регистрации"
                                    value={state.token.value}
                                    onBlur={validateTokenAvailability}
                                    onChange={(event) => handleInputChange(event, validateToken)} />
                            </FormItem>
                            <Tooltip title="Код указан в квитанции">
                                <Typography.Link >Где взять?</Typography.Link>
                            </Tooltip>
                        </Space>
                    </FormItem>
                    <FormItem
                        //                           label="Логин"
                        hasFeedback
                        validateStatus={state.username.validateStatus}
                        help={state.username.errorMsg}>
                        <Input
                            size="large"
                            name="username"
                            autoComplete="off"
                            placeholder="Ваш login"
                            value={state.username.value}
                            onBlur={validateUsernameAvailability}
                            onChange={(event) => handleInputChange(event, validateUsername)} />
                    </FormItem>
                    <FormItem
                        //                           label="Email"
                        hasFeedback
                        validateStatus={state.email.validateStatus}
                        help={state.email.errorMsg}>
                        <Input
                            size="large"
                            name="email"
                            type="email"
                            autoComplete="off"
                            placeholder="Ваш email"
                            value={state.email.value}
                            onBlur={validateEmailAvailability}
                            onChange={(event) => handleInputChange(event, validateEmail)} />
                    </FormItem>
                    <FormItem
                        //                           label="Пароль"
                        validateStatus={state.password.validateStatus}
                        help={state.password.errorMsg}>
                        <Input
                            size="large"
                            name="password"
                            type="password"
                            autoComplete="off"
                            placeholder="Пароль от 6 to 20 символов"
                            value={state.password.value}
                            onChange={(event) => handleInputChange(event, validatePassword)} />
                    </FormItem>
                    <FormItem
                        validateStatus={state.agreement.validateStatus}
                        help={state.agreement.errorMsg}>
                        <Checkbox
                            name="agreement"
                            // checked = {this.state.agreement.value} 
                            onChange={(event) => handleCheckChange(event, validateAgreement)}
                        >
                            <Typography.Text>Настоящей регистрацией Вы принимаете условия <a href="/rules">Пользовательского соглашения</a>  на условиях и для целей, определённых <a href="/warning">Политикой обработки песональных данных</a> и даёте свое согласие ООО "ПИРЦ" на обработку своих персональных данных, в соответствии с Федеральным законом от 27.07.2006 г. № 152 - ФЗ "О персональных данных".</Typography.Text>
                        </Checkbox>

                    </FormItem>
                    <FormItem
                    //   label=" " 
                    // wrapperCol={{
                    //     offset: 5,
                    //     span: 20,
                    //     }}
                    >

                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="signup-form-button button"
                            disabled={isFormInvalid()}>Зарегистрироваться</Button>
                        Уже зарегистрированы? <Link to="/login">Войти!</Link>
                    </FormItem>
                </Form>
            </div>
        </div>
    );




}

export default Signup;