import React, { useState, useEffect,useRef,useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";
import { getUserProfile, getFlat, getAmount, getReport, getMailReport } from '../../util/APIUtils';
import LoadingIndicator from '../../common/LoadingIndicator';
import NotFound from '../../common/NotFound';
import EventBus from '../../common/EventBus';
import ServerError from '../../common/ServerError';
import CheckAuthentication from '../../common/CheckAuthentication';
import { NumericFormat }  from "react-number-format";
import { getPeriodNames } from '../../util/Helpers';
import { Select, Button, notification,Typography , Modal} from "antd";
import './Amount.css';
import '../../App.css'
import Table from '../../components/Table';
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";
import InputNode from '../../components/InputNode';
import { Payment } from '../../util/Payment2';
const { Option } = Select;

const Amount = (props) => {
    // const [amounts, setAmounts] = useState([]);
    // const { username } = useParams();
    const [user, setUser] = useState();
    const [modaldata, setmodaldata] = useState([]);
    const {
        isAuthenticated,
        currentUser,
        ...restProps
    } = props;
    // const [comission, setcomission] = useState(0);
    // const [total, settotal] = useState(0);
    // const [flatid, setFlatId] = useState();
    // const [selectedflat, setSelectedFlat] = useState();
    // const [flats, setFlats] = useState([]);
    // const [user, setUser] =useState();
    const init =  {
        flats:[],
        address:'Выбор помещения',
        // // user: null,
        // isLoading: false,
        // error: null,
        // selectedFlatId: null,
        // selectedFlat: null,
        btdisable: true,
        // validatestatus: ''
    };
    const [store, setStore] = useState(init);

    // WHEN A USER IS SELECTED
    const handleFlatSelect = (flatId) => {

        // setFlatId(flatId);
        let flat = store.flats.find(item => item.flat === flatId);
        // setSelectedFlat(flat);
        if (user !== null) {
            setStore({
                ...store,
                isLoading: true
            })
        }
        const request = {
            right: flat.right,
            period: flat.period
        }
        getAmount(request)
            .then(response => {
                setStore({
                    ...store,
                    amounts: response.listAmount,
                    // originData: response.listAmount,
                    isLoading: false, btdisable: false, 
                    selectedFlatId: flatId,
                    selectedFlat: flat,
                    address: flat.address
                });
                // setAmounts(response.listAmount);
            }).catch(error => {
                if (error.status === 404) {
                    setStore({
                        ...store,
                        notFound: true,
                        isLoading: false, btdisable: false, 
                        selectedFlatId: flatId,
                        selectedFlat: flat
                    });
                } else if (error.status === 406) {
                    setStore({
                        ...store,
                        serverError: true,
                        isLoading: false, btdisable: false, 
                        selectedFlatId: flatId,
                        selectedFlat: flat
                    });
                    EventBus.dispatch("handleLogout");
                } else {
                    setStore({
                        ...store,
                        serverError: true,
                        isLoading: false, btdisable: false, 
                        selectedFlatId: flatId,
                        selectedFlat: flat
                    });
                }
            });
    }

    const Report = () => {
        const flat = store.selectedFlat;
        if (user !== null) {
            setStore({
                ...store,
                isLoading: true
            })
        }
        const request = {
            right: flat.right,
            period: flat.period
        }
        getReport(request)
            .then(response => {
                setStore({
                    ...store,
                    isLoading: false
                });
            })
            .catch(error => {
                if (error.status === 404) {
                    setStore({
                        ...store,
                        notFound: true,
                        isLoading: false
                    });
                } else if (error.status === 406) {
                    setStore({
                        ...store,
                        serverError: true,
                        isLoading: false
                    });
                    EventBus.dispatch("handleLogout");
                } else {
                    setStore({
                        ...store,
                        serverError: true,
                        isLoading: false
                    });
                }
            });
    }
    const MailReport = () => {
        const flat = store.selectedFlat;
        const request = {
            right: flat.right,
            period: flat.period
        }
        getMailReport(request)
            .then(response => {
                setStore({
                    ...store,
                    user: response,
                    isLoading: false
                });
                notification.success({
                    message: 'Портал ЖКХ',
                    description: response.data,
                });
            }).catch(error => {
                if (error.status === 404) {
                    setStore({
                        ...store,
                        notFound: true,
                        isLoading: false
                    });
                } else if (error.status === 406) {
                    setStore({
                        ...store,
                        serverError: true,
                        isLoading: false
                    });
                    EventBus.dispatch("handleLogout");
                } else {
                    setStore({
                        ...store,
                        serverError: true,
                        isLoading: false
                    });
                    notification.error({
                        message: 'Портал ЖКХ',
                        description: error.message,
                    });
                }
            });
    }

    const columns = [
        {
            title: 'Период',
            dataIndex: 'period',
            key: 'period',
            editable: false,
            render: (text) => getPeriodNames(text)
        },
        {
            title: 'Наименование',
            dataIndex: 'namevkvit',
            key: 'namevkvit',
            editable: false,
            // render(text) {
            //     return {
            //       props: {
            //         style: { color: '#1890FF' }
            //       },
            //       children: <div>{text}</div>
            //     };
            //   }
        },
        {
            title: 'Лицевой счет',
            dataIndex: 'accname',
            key: 'accname',
            editable: false,
            // render: (text)=> <a>{text}</a>
        },
        {
            title: 'Начислено',
            dataIndex: 'amount1',
            key: 'amount1',
            editable: false,
            inputType: "nsumm",
            align: "right",
            render: (text) => <NumericFormat  decimalScale={2} fixedDecimalScale={true} displayType="text" value={text} />
        },
        {
            title: 'Долг (Аванс)',
            dataIndex: 'dolg',
            key: 'dolg',
            editable: false,
            inputType: "summ",
            align: "right",
            // render: (text)=>  <NumberFormat decimalScale={2} fixedDecimalScale={true} displayType="text" value={text} />
            // render(text) {
            //     return {
            //       props: {
            //         style: { color: parseFloat(text) > 0 ? "red" : "green" }
            //       },
            //       children: <NumberFormat decimalScale={2} fixedDecimalScale={true} displayType="text" value={text} />
            //     };
            //   }
        },
        {
            title: 'К оплате',
            dataIndex: 'amount',
            key: 'amount',
            editable: true,
            inputType: "summ",
            align: "right",
            // render: (text)=>  <NumberFormat decimalScale={2} fixedDecimalScale={true} displayType="text" value={text} />
        },
        { 
            dataIndex: "gpay",
            title: "Оплата",
            editable: false, 
            render: (index, record) =>( <Button type="primary" disabled={!record.payment}
            onClick={() => showModal(record)}>
            Оплатить</Button>) }
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (record) => {
        // console.log(record);
        setmodaldata(record);
        // console.log('record ',record);
        // console.log('modaldata1 ',modaldata)
        setmodaldata((old) => ({ ...old,  sumcomission: round(record.amount* record.comission/(100 -old.comission),2) , total:  Number(record.amount) + round(record.amount* record.comission/(100 -old.comission),2)  , isOKdisabled : round(record.amount* (1+record.comission/(100 -old.comission)),2)===0   }));
        // console.log('modaldata2 ',modaldata);
        // setisOKdisabled(modaldata.total==0);
        setIsModalOpen(true);
      };
    const handleOk = () => {
      setIsModalOpen(false);
      const request={
        amount : modaldata.total,
        description : modaldata.description,
        terminal : modaldata.terminal,
        account : modaldata.account,
        period : modaldata.period,
        merchant : modaldata.merchant,
        comission : modaldata.sumcomission,
        key : modaldata.key
    }
    setStore({
        ...store,
        isLoading: true
    });
        Payment(request);
    }
    const handleCancel = () => {
      setIsModalOpen(false);
    }
    const handleInputChange = (value, dataIndex) => {
        setmodaldata((old) => ({ ...old, amount: value , sumcomission: round(value* old.comission/(100 -old.comission),2), total: Number(value) + round(value* old.comission/(100 -old.comission),2) , isOKdisabled : round(value* (1+old.comission/(100 -old.comission)),2)===0 }));
      };
    const loadUserProfile = async (username) => {

        //      console.log('Home0 ');
        if (username !== null) {
            setStore({
                ...store,
                isLoading: true
            });
            await getFlat()
                .then(response => {
                    setStore({
                        ...store,
                        flats: response.listFlat
                    });
                    // setFlats(response.listFlat);
                }).catch(error => {
                    if (error.status === 404) {
                        setStore({
                            ...store,
                            notFound: true,
                            isLoading: false
                        });
                    } else if (error.status === 406) {
                        setStore({
                            ...store,
                            serverError: true,
                            isLoading: false
                        });
                        EventBus.dispatch("handleLogout");
                    } else {
                        setStore({
                            ...store,
                            serverError: true,
                            isLoading: false
                        });
                    }
                });
        }
    }
    useEffect (() => {
        setUser(currentUser.username);
        setmodaldata(() => ({  sumcomission: 0, total: 0 , isOKdisabled : false }));
        let isSubscribed = true;
        loadUserProfile(user);
        return () => isSubscribed = false;
    }, []);

    if (store.isLoading) {
        return <LoadingIndicator />;
    }

    if (store.notFound) {
        return <NotFound />;
    }

    if (store.serverError) {
        return <ServerError />;
    }
    if (!props.isAuthenticated) {
        return <CheckAuthentication {...props} />
    }

    return (
        <div className="home-content">
            {
                user ? (
                    <div>
                        <Typography.Title level={3}>Платежные документы</Typography.Title>
                        <Select
                            className="select"
                            defaultValue="Выбор помещения"
                            style={{ width: 500, marginBottom: 20 }}
                            onChange={handleFlatSelect}
                            value={store.address}
                        >
                            {store.flats.map((flat) => (
                                <Option key={flat.flat} value={flat.flat}>
                                    {flat.address}
                                </Option>
                            ))}
                        </Select>
                        <div>
                            <Table
                                headers={columns}
                                rows={store.amounts}
                            />
        <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={300} okButtonProps={{ disabled: modaldata.isOKdisabled }}>
        <p>Сумма платежа <InputNode
        // style={{ margin: 0 }}
        inputType='summ'
        width={100}
        value={modaldata.amount}
        handleInputChange={(value) => {
          handleInputChange(value);
        }}
      /></p>
          <p className="numeric-input" >Комиссия:  {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(modaldata.sumcomission)}</p>
          <h2 className="numeric-input">Итого:  {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(modaldata.total)}</h2>
        </Modal>
                            <table className="button-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <Button type="primary" htmlType="submit" size="large" className="login-form-button button" icon={<DownloadOutlined />} onClick={() => Report()} disabled={store.btdisable} >Загрузить платежный документ</Button>
                                        </td>
                                        <td>
                                            <Button type="primary" htmlType="submit" size="large" className="login-form-button button" icon={<MailOutlined />} onClick={() => MailReport()} disabled={store.btdisable} >Отправить на электронную почту</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                ) : null
            }
        </div>
    )

}
function round(value, decimals) {
    return Math.round(value* Math.pow(10, decimals)) / Math.pow(10, decimals);
}
export default Amount;