import React, { Component, useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Link,
    useLocation, useSearchParams 
  } from "react-router-dom";
import LoadingIndicator from '../common/LoadingIndicator';
import NotFound from '../common/NotFound';
import ServerError from '../common/ServerError';
import CheckAuthentication from '../common/CheckAuthentication';
const ConfirmPayment =(props) => {
const [searchParams, setSearchParams] = useSearchParams();
const [state, setState] = useState(
 {
            result:null,
            isConfirm:false,
            message:''
        }
    );


    useEffect(() => {
     const status = searchParams.get('status');
     const merchant_order_id = searchParams.get('merchant_order_id');
     const order_id = searchParams.get('order_id');
     const code = searchParams.get('code');
     const checksum = searchParams.get('checksum');
     const message = searchParams.get('message');
        setState({...state,
            result: status,
            merchant_order_id :merchant_order_id,
            order_id :order_id,
            code : code,
            checksum : checksum,
            message : message,
            isLoading: false
        }); 
        const request ={
          result: state.status,
          merchant_order_id :state.merchant_order_id,
          order_id :state.order_id,
          code : state.code,
          checksum : state.checksum,
          message : state.message  
        }

      }, [searchParams]);
      if (state.isLoading) {
        return <LoadingIndicator />;
    }

    if (state.notFound) {
        return <NotFound />;
    }

    if (state.serverError) {
        return <ServerError />;
    }
    if (!props.isAuthenticated) {
        return <CheckAuthentication {...props} />
    }
    return (
      <div>  {state.result==='success' ? (
        <div className="App-body"> Операция подтверждена. Квитанция об оплате отправлена на Вашу электронную почту.</div>

      ):(
        <div className="App-body">Операция отклонена.</div>
      )
      }
      
      </div>
    )
}
export default ConfirmPayment;