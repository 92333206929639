import React, { useState } from 'react';
import { Input, Button, notification, Form } from 'antd';
import { checkEmailAvailability, resetpasswordlink } from '../../util/APIUtils';
import { Link,  useNavigate } from 'react-router-dom';
import { EMAIL_MAX_LENGTH,  EMAIL_REGEX } from '../../constants';
import 'antd/dist/reset.css';
import './forgotpassword.css';

const FormItem = Form.Item;

const Forgotpassword = (props) => {
    const [form, setForm] = useState({ email: { value: '' } });
    // const history = useHistory();
    let navigate = useNavigate();
    const handleInputChange = (event, validationFun) => {
        // console.log('Targetvalue:', event.target.value);
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        setForm({
            ...form,
            [inputName]: {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    const handleSubmit = (values) => {
        const signupRequest = {
            email: form.email.value
        };
        // console.log('Received values of form: ', signupRequest);
        resetpasswordlink(signupRequest)
            .then(response => {
                notification.success({
                    message: 'Портал ЖКХ',
                    description: response.data,
                });
                navigate('/login');
            }).catch(error => {
                notification.error({
                    message: 'Портал ЖКХ',
                    description: error.data || 'Извините! Что-то пошло не так. Пожалуйста, попробуйте позднее!'
                });
            });
    }

    // function isFormInvalid() {
    //     return !(email.validateStatus === 'success'
    //     );
    // }
    const validateEmail = (email) => {
        if (!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email не может быть пустым'
            }
        }

        // const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Некорректный email'
            }
        }

        if (email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }
    const validateEmailAvailability = () => {
        // First check for client side errors in email
        const emailValue = form.email.value;
        const emailValidation = validateEmail(emailValue);

        if (emailValidation.validateStatus === 'error') {
            setForm({
                ...form, email: {
                    value: emailValue,
                    ...emailValidation
                }
            });
            return;
        }
        setForm({
            ...form, email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
            .then(response => {
                if (response.data === "false") {
                    setForm({
                        ...form, email: {
                            value: emailValue,
                            validateStatus: 'error',
                            errorMsg: 'Email не зарегистрирован'
                        }
                    });
                } else {
                    setForm({
                        ...form, email: {
                            value: emailValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                setForm({
                    ...form, email: {
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: null
                    }

                });
            });
    }
    return (
        <div className="forgotpassword-container">
            <div className="forgotpassword-content">
                <Form onFinish={handleSubmit} className="forgotpassword-form"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}>
                    <FormItem
                        wrapperCol={{
                            offset: 4,
                            span: 20,
                        }}
                    >
                        <h1 className="page-title">Восстановление пароля</h1>
                    </FormItem>
                    <FormItem
                        // label="Введите Email"
                        wrapperCol={{
                            offset: 4,
                            span: 20,
                        }}
                        hasFeedback
                        validateStatus={form.email.validateStatus}
                        help={form.email.errorMsg}>
                        <Input
                            size="large"
                            name="email"
                            type="email"
                            autoComplete="off"
                            placeholder="Ваш email"
                            value={form.email.value}
                            onBlur={validateEmailAvailability}
                            onChange={(event) => handleInputChange(event, validateEmail)} />
                    </FormItem>
                    <FormItem
                        wrapperCol={{
                            offset: 4,
                            span: 20,
                        }}
                    >
                        <Button type="primary"
                            htmlType="submit"
                            size="large"
                            className="forgotpassword-form-button"
                        //                               disabled={this.isFormInvalid()}
                        >Продолжить</Button>
                        Уже восстановили? <Link to="/login">Войдите!</Link>
                    </FormItem>
                </Form>
            </div>
        </div>
    );



}

export default Forgotpassword;