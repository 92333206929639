import React from "react";
import Styled from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined, CheckCircleTwoTone , CloseCircleTwoTone, EditTwoTone
} from "@ant-design/icons";

const ButtonGroupTableEditContainer = Styled.div`
  width: 65px;
  margin: 0 5px;
  display: flex;
  justify-content: space-between;
`;

const ButtonGroupTableEdit = ({ editing, eventHandlers, rowKey, disabled }) => {
  const { editRow, deleteRow, saveRow, cancelRow } = eventHandlers;

  return !editing ? (
    <ButtonGroupTableEditContainer>
      {!disabled ?
      (<EditTwoTone style={{ fontSize: '22px' }}  onClick={() => editRow(rowKey)} />)
      :(<EditOutlined  style={{ fontSize: '22px' }} />)}

    </ButtonGroupTableEditContainer>
  ) : (
    <ButtonGroupTableEditContainer>
      <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor="#52c41a" onClick={() => saveRow(rowKey)} />
      <CloseCircleTwoTone style={{ fontSize: '22px' }} twoToneColor="#eb2f96" onClick={() => cancelRow()} />
    </ButtonGroupTableEditContainer>
  );
};

export default ButtonGroupTableEdit;
